import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./ProjectList.scss";
import RenderContent from "../../RenderContent";
import Pagination from "../../Pagination";
import { Image } from "../../Image";
import PostListItem from "../../Blog/PostListItem";
import { ProjectListNavigation } from "../ProjectListNavigation";

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categories, pathPrefix, siteMetadata } =
      this.props;
    return (
      <section className="project-list">
        {/* <ProjectListNavigation categories={categories}/> */}
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map(
                  (slide, inedx) =>
                    !slide.node.slug.includes("gatsby") && (
                      <PostListItem
                        key={inedx}
                        data={slide.node}
                        siteMetadata={siteMetadata}
                        pathPrefix={pathPrefix}
                      />
                    )
                )}
              </div>
              {/* <Pagination pageContext={pageContext} pathPrefix={pathPrefix} /> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    project_category
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
